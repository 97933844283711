<template>
    <div>
        <h2>{{ $t("payment.payment") }}</h2>
        <div class="box">
			<DateRange
                :start.sync="start_date"
                :end.sync="end_date"
                @submit="loadPayments"
            />

            <CustomTable
                id_table="payment_regul"
                ref="payment_regul"
                :items="payments"
                :busy.sync="table_busy"
                primaryKey="payment_id"
                :hrefsRoutes="config_table_hrefs"
                :hide_if_empty="true"
                :rawColumns="rawColumnNames"
				:externSlotColumns="extern_slot_columns"
            >
				<template v-slot:[`custom-slot-cell(payment_regulated)`]="{ data }">
					<font-awesome-icon class="text-success" v-if="data.payment_regulated" :icon="['fal', 'check-circle']" />
					<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
				</template>
			</CustomTable>
        </div>

        <b-modal ref="modalRegul" hide-footer>
			<template v-slot:modal-title>
				{{ $t("payment.regul_compta") }}
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded">
                <p>
                    {{ $t('payment.regulation_a_effectuer') }}
                </p>
				<label v-for="(recap, key) in tab_recap" :key="key">
                    {{recap.label}}
                </label>

				<div class="col-8 m-auto">
					<b-button v-if="ready" block pill variant="primary" @click="onSubmitMarkRegulated"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.valider_regulation") }}</b-button>
				</div>
			</div>
		</b-modal>
    </div>
</template>


<script type="text/javascript">
    import Vue from 'vue'
    import { EventBus } from 'EventBus'

    import TableAction from "@/mixins/TableAction.js"
    import Invoice from "@/mixins/Invoice.js"
    import Navigation from "@/mixins/Navigation.js"
    import Payment from "@/mixins/Payment.js"
    import Config from "@/mixins/Config.js"
    import _sortBy from 'lodash/sortBy'


    export default {
        name: "list",
        mixins: [Config, TableAction, Invoice, Navigation, Payment],
        props: [],
        data () {
            return {
                payments: null,
                table_busy: true,
                modal_loaded: false,
                ready: false,
                processing: false,
                config_table_hrefs: {
                    'tiers.tiers_rs': {
                        routeUniqueName: 'tiersFiche',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'tiers.tiers_id'
                        }
                    }
                },
                tab_recap: [],
                payments_selected: [],
                rawColumnNames: ['tiers.tiers_rs'],
                extern_slot_columns: ['payment_regulated'],
                events_tab: {
                    'TableAction::goToMarkAsRegulated': this.openModalMarkAsRegulated,
				},
				start_date: new Date(new Date().setMonth(new Date().getMonth() - 3)),
                end_date: new Date(),
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loadPayments()
            },
            async loadPayments() {
                this.table_busy = true
                const payments = await this.listRegulPayment(null, this.start_date, this.end_date)
                this.payments = await this.formatPaymentsRegul(payments)

                this.table_busy = false
            },
			async formatPaymentsRegul(payments) {
				let paymentsFormated = []

				if(!payments) {
					return []
				}

                for (let i = 0; i < payments.length; i++) {
                    const temp = payments[i]
                   
                    paymentsFormated.push(temp);
                }

                const tab_recap = []
                let payments_selected = _sortBy(paymentsFormated, 'payment_amount')

                for (let index = 0; index < payments_selected.length; index++) {
                    const element = payments_selected[index];
                    if(element.payment_amount < 0) {
                        const regul_payment = payments_selected.find(p => p.payment_regul == element.payment_id)
                        // element.payment_amount = await this.priceFormat((element.payment_amount * -1), "EUR", true)

                        tab_recap.push({
                            'label': this.getTrad('payment.virement_de') + ' ' + (element.payment_amount * -1) + element.accounting_plan.tiers.tiers_currency + ' ' + this.getTrad('payment.de') + ' ' + element.accounting_plan.accountingplan_label + ' ' + this.getTrad('payment.vers') + ' ' + regul_payment.accounting_plan.accountingplan_label,
                            'payment_date': element.payment_date,
                            'payment_amount': element.payment_amount,
                            'payment_id': element.payment_id,
                            'payment_regul': element.payment_regul,
                            'payment_regulated': element.payment_regulated,
                            'emetteur': element.accounting_plan.accountingplan_label,
                            'currency': element.accounting_plan.tiers.tiers_currency,
                            'destinataire': regul_payment.accounting_plan.accountingplan_label,
                        })
                    }
                }

				return tab_recap
			},
            
            async openModalMarkAsRegulated(payments_id) {
                this.modal_loaded = false
                this.ready = false
                this.$refs.modalRegul.show()

                this.tab_recap = this.payments.filter(p => payments_id.indexOf(p.payment_id) > -1)
                
                this.ready = true
                this.modal_loaded = true
            },
            async onSubmitMarkRegulated() {

                if(!this.processing) {
                    this.processing = true
                    let ids = []
                    this.tab_recap.map(p => {
                        ids.push(p.payment_id)
                        ids.push(p.payment_regul)
                    })

                    await this.markAsRegulated(ids)

                    await this.loadPayments()
                    this.$refs.modalRegul.hide()
                    this.processing = false
                }

            }
        },
        components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable') ,
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            DateRange: () => import('GroomyRoot/components/Inputs/DateRange')
        }
    }
</script>
